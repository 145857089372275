import { EscrowManagementModule } from './escrow-management/escrow-management.module';
import { AttendancesManagementModule } from './attendances-management/attendances-management.module';
import { RouterModule, Routes } from '@angular/router';

// Components
import { BaseComponent } from '../theme/base/base.component';
// Auth
import { CanAuthenticationGuard } from '../../core/auth/can-authentication-guard';
// Angular
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['bo-access'] },
    children: [
      {
        path: 'cockpit',
        loadChildren: () => import('../pages/cockpit/cockpit.module').then((m) => m.CockpitModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'implantation',
        loadChildren: () =>
          import('../pages/implantation-management/implantation-management.module').then(
            (m) => m.ImplantationManagementModule
          ),
      },
      {
        path: 'financial',
        loadChildren: () =>
          import('../pages/financial-management/financial-management.module').then((m) => m.FinancialManagementModule),
      },
      /*{
        path: 'users',
        loadChildren: () =>
          import('../pages/user-management/user-management.module').then((m) => m.UserManagementModule),
      },*/
      {
        path: 'account',
        loadChildren: () =>
          import('../pages/account-management/account-management.module').then((m) => m.AccountManagementModule),
      },
      {
        path: 'risk-management',
        loadChildren: () =>
          import('../pages/risk-management/risk-management.module').then((m) => m.RiskManagementModule),
      },
      {
        path: 'attendances-management',
        loadChildren: () =>
          import('../pages/attendances-management/attendances-management.module').then((m) => m.AttendancesManagementModule),
      },
      {
        path: 'prosperaenergy-management',
        loadChildren: () =>
          import('../pages/prosperaenergy-management/prosperaenergy-management.module').then(
            (m) => m.ProsperaenergyManagementModule
          ),
      },
      {
        path: 'tax-management',
        loadChildren: () => import('../pages/tax-management/tax-management.module').then((m) => m.TaxManagementModule),
      },
      {
        path: 'treasury',
        loadChildren: () => import('../pages/treasury/treasury.module').then((m) => m.TreasuryModule),
      },
      {
        path: 'escrow-management',
        loadChildren: () => import('../pages/escrow-management/escrow-management.module').then((m) => m.EscrowManagementModule),
      },
      {
        path: 'cards-management',
        loadChildren: () => import('../pages/cards-management/cards-management.module').then((m) => m.CardsManagementModule),
      },
      {
        path: 'billets',
        loadChildren: () => import('../pages/billets-management/billets-management.module').then((m) => m.BilletsManagementModule),
      },
      {
        path: 'liquidation',
        loadChildren: () => import('../pages/liquidation-management/liquidation-management.module').then((m) => m.LiquidationManagementModule),
      },
      {
        path: 'med-pix-management',
        loadChildren: () =>
          import('../pages/med-pix-management/med-pix-management.module').then((m) => m.MedPixManagementModule),
      },
      // { path: 'error/:type', component: ErrorPageComponent },
      {
        path: 'cnab-management',
        loadChildren: () => 
          import('../pages/cnab-management/cnab-management.module').then((m) => m.CnabManagementModule)
        },
      { path: '', redirectTo: 'cockpit/dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'cockpit/dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
