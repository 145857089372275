// Env
// Hammer JS
import 'hammerjs';

import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import xml from 'highlight.js/lib/languages/xml';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
// Highlight JS
import {HIGHLIGHT_OPTIONS, HighlightModule} from 'ngx-highlightjs';
// Angular in memory
// Perfect Scroll bar
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';

import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule, DOCUMENT, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {GestureConfig} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// Angular
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// NGRX
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateModule} from '@ngx-translate/core';
import {KeycloakAngularModule, KeycloakService} from '@wlp/ui-bs-oauth-bo';
import {UiBsTranslateBoModule} from '@wlp/ui-bs-translate-bo';

import {environment} from '../environments/environment';
// Modules
import {AppRoutingModule} from './app-routing.module';
// Components
import {AppComponent} from './app.component';
// CRUD
import {HttpUtilsService, LayoutUtilsService, TypesUtilsService} from './core/_base/crud';
// Layout Services
import {
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService
} from './core/_base/layout';
// Config
import {LayoutConfig} from './core/_config/layout.config';
import {CoreModule} from './core/core.module';
import {RiskManagementModule} from './views/pages/risk-management/risk-management.module';
// Partials
import {PartialsModule} from './views/partials/partials.module';
import {ThemeModule} from './views/theme/theme.module';
import {NgxSpinnerModule} from "ngx-spinner";
import { MatStepperModule } from '@angular/material/stepper';

registerLocaleData(localept, 'pt');

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    // if (appConfig.getConfig() === null) {
    //   appConfig.loadConfigs(new LayoutConfig().configs);
    // }
    appConfig.loadConfigs(new LayoutConfig().configs);
  };
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    {name: 'typescript', func: typescript},
    {name: 'scss', func: scss},
    {name: 'xml', func: xml},
    {name: 'json', func: json},
  ];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    HighlightModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreDevtoolsModule.instrument(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,
    KeycloakAngularModule,
    UiBsTranslateBoModule,
    RiskManagementModule,
    NgxSpinnerModule,
    MatStepperModule
  ],
  exports: [],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt'},
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService, DOCUMENT],
      multi: true,
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const {keycloakConfig} = environment;
      let redirectUrl = document.location.protocol + '//' + document.location.hostname;

      if (document.location.hostname.includes('localhost')) {
        redirectUrl += ':' + document.location.port;
      }

      try {
        await keycloak.init({
          config: keycloakConfig,
          initOptions: {
            onLoad: null,
            checkLoginIframe: false,
            // redirectUri: redirectUrl + '/app',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
          },
          bearerExcludedUrls: ['/assets'],
          enableBearerInterceptor: true,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
