// Angular
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
// RxJS
import {Observable} from 'rxjs';
// NGRX
import {UserInfoService, UserInfoProfile} from "@wlp/ui-bs-oauth-bo";
import {LayoutConfigService} from "../../../../../core/_base/layout";

@Component({
  selector: 'kt-user-profile',
  styleUrls: ['user-profile.component.scss'],
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {

  @Input() userDropdownStyle = 'light';
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  userProfile: UserInfoProfile;


  constructor(private userInfoService: UserInfoService, private cdr: ChangeDetectorRef) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // this.user$ = this.store.pipe(select(currentUser));
    this.loadUser();
    // console.log('user-profile')
  }

  /**
   * Log out
   */
  logout() {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }

    this.userInfoService.onLogout(redirectUrl);
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        // this.loadStatement(user.uuidWhiteLabel, user.uuidAccount);
        // console.log(user)
      }).finally(() => {
        this.cdr.detectChanges()
      });

    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }
}
