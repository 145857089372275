import {Injectable} from '@angular/core';
import {LayoutConfigService} from './layout-config.service';
import {Subject} from 'rxjs';
import {UserInfoService} from '@wlp/ui-bs-oauth-bo';

@Injectable()
export class MenuConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: any;

  /**
   * Service Constructor
   */
  constructor(private layoutConfigService: LayoutConfigService, private userInfoService: UserInfoService) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    return this.menuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    this.menuConfig = config;

    this.processRoles();

    this.onConfigUpdated$.next(this.menuConfig);
  }

  private rolesUserAccess() {
    const rolesUser = this.userInfoService.getRoles();
    return rolesUser.filter((obj) => obj.includes('bo-'));
  }

  /**
   * Config para exibir o menu Prospera
   */
  private async processRoles() {
    const menuOrigin = await Object.assign([], this.menuConfig.aside.items);
    const rolesUser = this.rolesUserAccess();
    // console.log('processRoles', rolesUser);
    this.menuConfig.aside.items = [];

    //console.log('menuOrigin', menuOrigin)

    if (rolesUser.includes('bo-access')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isAccess));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isAccess);
      }
    }

    if (rolesUser.includes('bo-manager-panic')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isManagerPanic));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isManagerPanic);
      }
    }

    if (rolesUser.includes('bo-psx-admin')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isPsxAccess));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isPsxAccess);
      }
    }

    if (rolesUser.includes('bo-wl-admin')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isAccessWL));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isAccessWL);
      }
    }

    if (rolesUser.includes('bo-admin')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isAdminAccess));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isAdminAccess);
      }
    }

    if (rolesUser.includes('bo-ppr-admin')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isProsperaAccess));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isProsperaAccess);
      }
    }

    if (rolesUser.includes('bo-manual-rescue')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isManualRescue));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isManualRescue);
      }
    }

    if (rolesUser.includes('bo-lead')) {
      if (this.menuConfig.aside.items.length > 0) {
        this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isLead));
      } else {
        this.menuConfig.aside.items = menuOrigin.filter(this.isLead);
      }
    }
  }

  isAccess(element, index, array) {
    if (element?.roles) {
      // console.log('isAccess');
      const roles = element.roles.filter((obj) => obj.includes('bo-access'));
      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isManagerPanic(element, index, array) {
    if (element?.roles) {
      const roles = element.roles.filter((obj) => obj.includes('bo-manager-panic'));

      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isAccessWL(element, index, array) {
    if (element?.roles) {
      // console.log('isAccessWL');
      const roles = element.roles.filter((obj) => obj.includes('bo-wl-admin'));
      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isAdminAccess(element, index, array) {
    // console.log('isAdminAccess');
    if (element?.roles) {
      const roles = element.roles.filter((obj) => obj.includes('bo-admin'));
      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isProsperaAccess(element, index, array) {
    // console.log('isProsperaAccess');
    if (element?.roles) {
      // console.log(element.title, element.roles.filter((obj) => obj.includes('bo-access')));
      const roles = element.roles.filter((obj) => obj.includes('bo-ppr-admin'));

      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isPsxAccess(element, index, array) {
    // console.log('isProsperaAccess');
    if (element?.roles) {
      // console.log(element.title, element.roles.filter((obj) => obj.includes('bo-access')));
      const roles = element.roles.filter((obj) => obj.includes('bo-psx-admin'));

      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isManualRescue(element, index, array) {
    if (element?.roles) {
      const roles = element.roles.filter((obj) => obj.includes('bo-manual-rescue'));

      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isLead(element, index, array) {
    if (element?.roles) {
      const roles = element.roles.filter((obj) => obj.includes('bo-lead'));

      if (roles && roles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
