// Angular
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';
// Pages
import { CoreModule } from '../../core/core.module';
import { FormsModule } from '@angular/forms';
import { InterceptService } from '../../core/intercept/intercept.service';
import { PagesRoutingModule } from './pages-routing.module';
// Partials
import { PartialsModule } from '../partials/partials.module';
import { UiBsUserInterestModule } from '@wlp/ui-bs-user-interest';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    PagesRoutingModule,
    UiBsUserInterestModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
