import { Subscription } from 'rxjs';

// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as ptLang } from './core/_config/i18n/en';
import Swal from "sweetalert2";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Metronic';
  wlName: string;
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  lat: number = 0;
  long: number = 0;

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private titleService: Title
  ) {
    // register translations
    this.translationService.loadTranslations(ptLang);
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.titleService.setTitle(this.wlName + ' | Backoffice');

    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('page-loader.type');

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.setPropertyStyles();
    this.getCurrentLocation();
  }

  getCurrentLocation() {
    try {
      if (navigator.geolocation) {
        // console.log(navigator.geolocation);
        navigator.geolocation.getCurrentPosition(position => {
          sessionStorage.setItem('_lat', position.coords.latitude.toString());
          sessionStorage.setItem('_long', position.coords.longitude.toString());
        }, (positionError) => {
          console.warn(positionError);
          this.notificationEnableGeoLocalization();
        });
      }
      else {
        this.notificationNoAvailableGeoLocalization();
        console.log('Geolocation is not supported by this browser.');
      }
    }catch (e) {
      this.notificationNoAvailableGeoLocalization();
      console.log('Geolocation is not supported by this browser.', e);
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  setPropertyStyles() {
    // Aside Vars
    const asideBgColor = this.layoutConfigService.getConfig('wlTheme.aside.background.color');
    const asideTxtColor = this.layoutConfigService.getConfig('wlTheme.aside.text.color');
    const asideTxtHover = this.layoutConfigService.getConfig('wlTheme.aside.text.hover');

    // Aside Sets
    document.documentElement.style.setProperty('--ui-t-aside-background-color', asideBgColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-color', asideTxtColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-hover', asideTxtHover);

    // Text Color Vars
    const textPrimaryColor = this.layoutConfigService.getConfig('js.colors.theme.base.primary');
    const textSecondaryColor = this.layoutConfigService.getConfig('js.colors.theme.base.secondary');

    // Text Color Sets
    document.documentElement.style.setProperty('--ui-t-wl-primary', textPrimaryColor);
    document.documentElement.style.setProperty('--ui-t-wl-secondary', textSecondaryColor);
  }

  private notificationEnableGeoLocalization() {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      text: 'Para continuar, por favor, habilite a GeoLocalização do seu navegador e recarregue a página.',
      showCancelButton: false,

    }).then( (result ) => {
      window.location.href = '/app/';
    });
  }

  private notificationNoAvailableGeoLocalization() {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      text: 'Este navegador não tem suporte a GeoLocalização, por favor, atualize o navegador!',
      showCancelButton: false,

    }).then( (result ) => {
      window.location.href = '/app/';
    });
  }
}
