import {RouterModule, Routes} from '@angular/router';

import {CommonModule} from '@angular/common';
// Angular
import {NgModule} from '@angular/core';

const routes: Routes = [
  { path: 'error', loadChildren: () => import('./views/pages/error/error.module').then((m) => m.ErrorModule) },

  { path: '', redirectTo: 'app/cockpit/dashboard', pathMatch: 'full' },

  { path: 'app', loadChildren: () => import('./views/theme/theme.module').then((m) => m.ThemeModule) },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
